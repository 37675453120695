import React from "react";
import { useLanguage } from "../../LanguageContext";

const LanguageToggle = () => {
  const { language, toggleLanguage } = useLanguage();

  return (
    <div className="translate-toggler" onClick={toggleLanguage}>
      {language === "en" ? "EN" : "FI"}
    </div>
  );
};

export default LanguageToggle;
