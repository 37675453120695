import React, { useState, useEffect } from "react";

import { useLanguage } from "../LanguageContext";

const content = {
  en: {
    cookieTit: "This website uses cookies",
    cookieDesc:
      "This website uses cookies to ensure you get the best experience on our website. Website uses functionality cookies if you decide to contact me using the contact form. These cookies help's collect and process the information you submit, such as your name, email address, and any message you provide.",
    cookieAccept: "Accept",
    cookieDecline: "Decline",
  },
  fi: {
    cookieTit: "Tämä sivusto käyttää evästeitä",
    cookieDesc:
      "Tämä sivusto käyttää toiminnallisia evästeitä, jos päätät ottaa minuun yhteyttä käyttämällä yhteydenottolomaketta. Nämä evästeet auttavat keräämään ja käsittelemään sinun antamasi tiedot, kuten nimesi, sähköpostiosoitteesi ja mahdollisen viestin.",
    cookieAccept: "Hyväksy",
    cookieDecline: "Hylkää",
  },
};

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [startHideAnimation, setStartHideAnimation] = useState(false);
  const { language } = useLanguage();

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (consent !== "given") {
      setShowBanner(true);
    } else {
    }
  }, []);

  const handleAccept = () => {
    setStartHideAnimation(true);
    setTimeout(() => {
      localStorage.setItem("cookieConsent", "given");
      setShowBanner(false);
    }, 300); // Match this duration to your CSS transition duration
  };

  const handleDecline = () => {
    setStartHideAnimation(true);
    setTimeout(() => {
      setShowBanner(false);
    }, 300); // Match this duration to your CSS transition duration
  };

  if (!showBanner) return null;

  return (
    <>
      {showBanner && <div className="overlay"></div>}
      {showBanner && (
        <div
          className={`offcanvas-bottom ${
            startHideAnimation ? "hide-banner" : ""
          }`}
        >
          <h3 className="offcanvas-header">{content[language].cookieTit}</h3>
          <p className="cookie-message">{content[language].cookieDesc}</p>
          <div className="button-container">
            <button
              className="btn-cookie btn-outline-primary-cookie"
              onClick={handleAccept}
            >
              {content[language].cookieAccept}
            </button>
            <button
              className="btn-cookie btn-outline-primary-cookie"
              onClick={handleDecline}
            >
              {content[language].cookieDecline}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
