import React from "react";
import { Link } from "react-scroll";
import { useLanguage } from "../../LanguageContext";

export default function HeroSection() {
  const { language } = useLanguage();

  const content = {
    en: {
      heroTitle: "Hey, I'm Jonatan.",
      title: (
        <>
          <span className="hero--section--title--color">Full Stack</span>
          <br />
          Developer
        </>
      ),
      description: (
        <>
          I thrive on overcoming challenges and crafting effective solutions.
          <br />
          Explore my skills and projects below!
        </>
      ),
      buttonText: "Get in Touch",
      imageAlt: "Awesome Image",
    },
    fi: {
      heroTitle: "Hei, olen Jonatan",
      title: (
        <>
          <span className="hero--section--title--color">Full Stack</span>
          <br />
          Kehittäjä
        </>
      ),
      description: (
        <>
          Nautin haasteista sekä tehokkaiden ratkaisujen luomisesta.
          <br />
          Käy kurkkaamassa alta taitoni ja projektini!
        </>
      ),
      buttonText: "Ota yhteyttä",
      imageAlt: "Hieno Kuva",
    },
  };

  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content--box">
        <div className="hero--section--content">
          <p className="section--title">{content[language].heroTitle}</p>
          <h1 className="hero--section--title">{content[language].title}</h1>
          <p className="hero--section-description">
            {content[language].description}
          </p>
        </div>
        <Link
          activeClass="navbar--active-content"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1500}
          to="Contact"
        >
          <button className="btn btn-primary">
            {content[language].buttonText}
          </button>
        </Link>
      </div>
      <div className="hero--section--img">
        <img src="./img/hero_img.webp" alt={content[language].imageAlt} />
      </div>
    </section>
  );
}
