import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";

import { useLanguage } from "../../LanguageContext";

function Navbar() {
  const [navActive, setNavActive] = useState(false);
  const { language } = useLanguage();

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  const closeMenu = () => {
    setNavActive(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        closeMenu();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (window.innerWidth <= 1200) {
      closeMenu();
    }
  }, []);

  const getNavbarContent = () => {
    return {
      en: {
        home: "Home",
        aboutMe: "About Me",
        projects: "Projects",
        services: "Other Services",
        contact: "Contact Me",
      },
      fi: {
        home: "Etusivu",
        aboutMe: "Minusta",
        projects: "Projektit",
        services: "Muut Palvelut",
        contact: "Ota yhteyttä",
      },
    };
  };

  const navbarContent = getNavbarContent();

  return (
    <nav className={`navbar ${navActive ? "active" : ""}`}>
      <img className="logo" src="./img/logo512.png" alt="Logo" />
      <a
        className={`nav__hamburger ${navActive ? "active" : ""}`}
        onClick={toggleNav}
      >
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
      </a>
      <div className={`navbar--items ${navActive ? "active" : ""}`}>
        <ul>
          <li>
            <Link
              onClick={closeMenu}
              activeClass="navbar--active-content"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              to="heroSection"
              className="navbar--content"
            >
              {navbarContent[language].home}
            </Link>
          </li>
          <li>
            <Link
              onClick={closeMenu}
              activeClass="navbar--active-content"
              spy={true}
              smooth={true}
              offset={-70}
              duration={700}
              to="AboutMe"
              className="navbar--content"
            >
              {navbarContent[language].aboutMe}
            </Link>
          </li>
          <li>
            <Link
              onClick={closeMenu}
              activeClass="navbar--active-content"
              spy={true}
              smooth={true}
              offset={-70}
              duration={700}
              to="MyPortfolio"
              className="navbar--content"
            >
              {navbarContent[language].projects}
            </Link>
          </li>
          <li>
            <Link
              onClick={closeMenu}
              activeClass="navbar--active-content"
              spy={true}
              smooth={true}
              offset={-70}
              duration={700}
              to="Services"
              className="navbar--content"
            >
              {navbarContent[language].services}
            </Link>
          </li>
        </ul>
      </div>
      <Link
        onClick={closeMenu}
        activeClass="navbar--active-content"
        spy={true}
        smooth={true}
        offset={-70}
        duration={700}
        to="Contact"
        className="btn btn-outline-primary"
      >
        {navbarContent[language].contact}
      </Link>
    </nav>
  );
}

export default Navbar;
