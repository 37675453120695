import * as React from "react";
import { Tooltip } from "@mui/joy";

import { useLanguage } from "../../LanguageContext";

export default function Testimonial() {
  const { language } = useLanguage();

  const techClick = () => {
    window.open("https://tech.codewize.fi/", "_blank");
  };

  const content = {
    en: {
      contentTitle: "Services",
      title: "Other Services",
      serviceMusicTitle: "Music Production",
      serviceMusicDesc:
        "With over 12+ years of experience in music production, I offer services in music production, mixing, and mastering.",
      serviceTechTitle: "Tech Support",
      serviceTechDesc:
        "I offer tech support services for individuals and businesses. Services include troubleshooting, software installation or hardware repairs.",
      buttonMusic: "In progress..",
      buttonTech: "Visit",
    },

    fi: {
      contentTitle: "Palvelut",
      title: "Muut palvelut",
      serviceMusicTitle: "Musiikin tuotanto",
      serviceMusicDesc:
        "Yli 12 vuoden vahvalla kokemuksella musiikin tuotannosta, tarjoan palveluita kuten: tuotanto, miksaus ja masterointi.",
      serviceTechTitle: "Tekninen tuki",
      serviceTechDesc:
        "Tarjoan teknistä tukea yksityishenkilöille ja yrityksille. Palveluihin kuuluu vianmääritys, ohjelmiston asennus tai laitteiston korjaukset.",
      buttonMusic: "Työn alla..",
      buttonTech: "Kurkkaa",
    },
  };

  return (
    <section className="services--section" id="Services">
      <div className="services--container--box">
        <div className="services--container">
          <p className="sub--title">{content[language].contentTitle}</p>
          <h2 className="section--heading">{content[language].title}</h2>
        </div>
      </div>
      <div className="services--section--container">
        {/* music prod */}
        <div className="services--section--card services--notallowed">
          <div className="services--section--img">
            <img
              src={"./img/music-prod.webp"}
              alt="Inside studio"
              className="services--section--img"
            />
          </div>
          <div className="services--section--card--content">
            <div>
              <h3 className="portfolio--section--title">
                {content[language].serviceMusicTitle}
              </h3>
              <p className="text-md">{content[language].serviceMusicDesc}</p>
            </div>
            <p className="text-sm services--link">
              {content[language].buttonMusic}
            </p>
          </div>
        </div>
        {/* tech support */}
        <Tooltip
          title="tech.codewize.fi"
          color="neutral"
          arrow
          placement="bottom"
          variant="solid"
        >
          <div onClick={techClick} className="services--section--card">
            <div className="services--section--img">
              <img
                src={"./img/tech-support.webp"}
                alt="Tech support"
                className="services--section--img"
              />
            </div>
            <div className="services--section--card--content">
              <div>
                <h3 className="portfolio--section--title">
                  {content[language].serviceTechTitle}
                </h3>
                <p className="text-md">{content[language].serviceTechDesc}</p>
              </div>
              <a
                href="https://tech.codewize.fi/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm services--link"
              >
                <p>{content[language].buttonTech}</p>
              </a>
            </div>
          </div>
        </Tooltip>
      </div>
    </section>
  );
}
