import React, { useState } from "react";
import {
  faCircleExclamation,
  faCircleCheck,
  faShareNodes,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLanguage } from "../../LanguageContext";

export default function ContactMe() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [errors, setErrors] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const { language } = useLanguage();

  function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    fetch("https://formcarry.com/s/23BtRUVgKzR", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, message }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.code === 200) {
          // alert("We received your submission, thank you!");
          setName("");
          setEmail("");
          setMessage("");
          setIsSubmitted(true);
          setSubmissionStatus(true); // Set submission status to true for success
        } else if (response.code === 422) {
          setErrors(response.message);
          setSubmissionStatus(false); // Set submission status to false for validation failure
        } else {
          setErrors(response.message);
          setSubmissionStatus(false); // Set submission status to false for other errors
        }
      })
      .catch((error) => {
        setErrors(error.message ? error.message : error);
        setSubmissionStatus(false); // Set submission status to false for request-related errors
      });
  }

  const content = {
    en: {
      contentTitle: <>Get In Touch</>,
      title: <>Contact Me</>,
      description: "If you would like to get anything, please fill the form",
      firstNametxt: "Name",
      lastNametxt: "Last Name",
      emailtxt: "Email",
      phoneNumbertxt: "Phone Number",
      messagetxt: "Message",
      messageContent: "Type your message",
      submitButton: "Submit",
      infoSocials: "Socials",
      infoContact: "Contact",
      submissionSuccessTxt: "Great! Message has been sent.",
      submissionSuccessTxt2:
        "Now sit and relax, we will be in contact shortly.",
    },

    fi: {
      contentTitle: <>Yhteydenotto</>,
      title: <>Ota yhteyttä</>,
      description:
        "Jos sinulla on kysyttävää tai haluaisit palvelun, ole hyvä ja täytä lomake",
      firstNametxt: "Nimi",
      lastNametxt: "Sukunimi",
      emailtxt: "Shkköposti",
      phoneNumbertxt: "Puhelinnumero",
      messagetxt: "Viesti",
      messageContent: "Aloita kirjoittamalla viestisi",
      submitButton: "Lähetä",
      infoSocials: "Some",
      infoContact: "Yhteystiedot",
      submissionSuccessTxt: "Hienoa! Viesti on lähetetty.",
      submissionSuccessTxt2:
        "Nyt voit ottaa rennosti, sillä välin kun käsittelemme viestisi.",
    },
  };

  return (
    <section id="Contact" className="contact--section">
      <div>
        <p className="sub--title">{content[language].contentTitle}</p>
        <h2>{content[language].title}</h2>
        <p className="text-lg margin--contact">
          {content[language].description}
        </p>
      </div>
      <div className="contact--div">
        <form
          className="contact--form--container"
          onSubmit={(e) => onSubmit(e)}
        >
          <div className="container">
            <label htmlFor="name" className="contact--label">
              <span className="text-md">{content[language].firstNametxt}</span>
              <input
                type="text"
                className={`contact--input text-md ${
                  errors.firstName && "error"
                }`}
                onChange={(e) => setName(e.target.value)}
                name="firstName"
                id="name"
                value={name}
                required
              />
              {errors.firstName && (
                <div className="error-message">
                  <FontAwesomeIcon icon={faCircleExclamation} />{" "}
                  {errors.firstName}
                </div>
              )}
            </label>

            <label htmlFor="email" className="contact--label">
              <span className="text-md">{content[language].emailtxt}</span>
              <input
                type="email"
                className={`contact--input text-md ${errors.email && "error"}`}
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {errors.email && (
                <div className="error-message">
                  <FontAwesomeIcon icon={faCircleExclamation} /> {errors.email}
                </div>
              )}
            </label>
          </div>

          <label htmlFor="message" className="contact--label">
            <span className="text-md">{content[language].messagetxt}</span>
            <textarea
              className={`contact--input text-md ${errors.message && "error"}`}
              id="message"
              name="message"
              rows="8"
              placeholder={content[language].messageContent}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {errors.message && (
              <div className="error-message">
                <FontAwesomeIcon icon={faCircleExclamation} /> {errors.message}
              </div>
            )}
          </label>

          <div>
            {submissionStatus === true && (
              <div className="confirmation-message success">
                <FontAwesomeIcon icon={faCircleCheck} size="lg" />{" "}
                {content[language].submissionSuccessTxt} <br></br>
                {content[language].submissionSuccessTxt2}
              </div>
            )}
            {submissionStatus === false && (
              <div className="confirmation-message error">
                <FontAwesomeIcon icon={faCircleExclamation} size="lg" />{" "}
                Submission failed. Please check your inputs. <br /> If the error
                persists, please contact jonatan.owizyc@codewize.fi directly
              </div>
            )}
          </div>

          <div>
            <button
              type="submit"
              className="btn btn-primary contact--form--btn"
            >
              {content[language].submitButton}
            </button>
          </div>
        </form>
        <hr className="gradient"></hr>
        <div className="info--section">
          <div class="info--flex">
            <FontAwesomeIcon
              className="info-icon"
              icon={faShareNodes}
              size="lg"
            />
            <div className="header--info">
              <strong className="info-text">
                {content[language].infoSocials}
              </strong>
              <p>
                <a className="info-link" href="https://github.com/xMal0c">
                  github.com/xMal0c
                </a>
              </p>
              <p>
                <a
                  href="https://www.linkedin.com/in/jonatanowizyc/"
                  className="info-link"
                >
                  linkedin.com/in/jonatanowizyc
                </a>
              </p>
            </div>
          </div>

          <div class="info--flex">
            <FontAwesomeIcon
              className="info-icon"
              icon={faEnvelope}
              size="lg"
            />
            <div className="header--info">
              <strong className="info-text">
                {content[language].infoContact}
              </strong>
              <p>
                <a className="info-link" href="/">
                  jonatan.owizyc@codewize.fi
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
