import "./App.css";
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { LanguageProvider } from "./LanguageContext";

import Navbar from "./Pages/Home/Navbar";
import Home from "./Pages/Home/Homescreen";
import NotFound from "./Pages/Home/404/404";
import DarkModeToggle from "./Pages/Home/DarkModeToggle";
import LanguageToggle from "./Pages/Home/LanguageTool";

import CookieConsentOffcanvas from "./components/CookieConsent";

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    const saved = localStorage.getItem("darkMode");
    const initialValue = JSON.parse(saved);
    return initialValue || false;
  });

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => {
      localStorage.setItem("darkMode", !prevMode);
      return !prevMode;
    });
  };

  return (
    <LanguageProvider>
      <div className={`App ${darkMode ? "dark-mode" : ""}`}>
        <Router>
          <div>
            <CookieConsentOffcanvas />
            <Navbar />
            <DarkModeToggle
              darkMode={darkMode}
              toggleDarkMode={toggleDarkMode}
            />
            <LanguageToggle />
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="*" element={<NotFound />}></Route>
            </Routes>
          </div>
        </Router>
      </div>
    </LanguageProvider>
  );
}

export default App;
