import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";

const DarkModeToggle = ({ darkMode, toggleDarkMode }) => {
  useEffect(() => {
    const root = document.documentElement;

    if (darkMode) {
      // Darkmode
      root.style.setProperty(
        "--heading-color",
        "var(--darkmode-heading-color)"
      );
      root.style.setProperty("--whitebackground", "var(--darkmodebackground)");
      root.style.setProperty("--black", "var(--darkmode-black)");
      root.style.setProperty("--bg-shade", "var(--darkmode-bg-shade)");
      root.style.setProperty("--darkblue", "var(--darkmode-blue)");
      root.style.setProperty("--navbar-bg-light", "var(--navbar-bg-dark)");
      root.style.setProperty(
        "--language-toggler-bg-light",
        "var(--language-toggler-bg-dark)"
      );
    } else {
      // Lightmode
      root.style.setProperty("--heading-color", "#282938");
      root.style.setProperty("--whitebackground", "#ffffff");
      root.style.setProperty("--black", "#000");
      root.style.setProperty("--bg-shade", "#fffef1");
      root.style.setProperty("--darkblue", "#1c1e53");
      root.style.setProperty("--navbar-bg-light", "rgba(249, 249, 249, 0.9)");
      root.style.setProperty(
        "--language-toggler-bg-light",
        "rgba(249, 249, 249, 0.9)"
      );
    }
  }, [darkMode]);

  return (
    <div
      className={`dark-mode-toggle ${darkMode ? "active" : ""}`}
      onClick={toggleDarkMode}
    >
      {darkMode ? (
        <FontAwesomeIcon icon={faSun} size="xl" />
      ) : (
        <FontAwesomeIcon icon={faMoon} size="xl" />
      )}
    </div>
  );
};

export default DarkModeToggle;
