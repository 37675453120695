import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faSquareArrowUpRight } from "@fortawesome/free-solid-svg-icons";

import { useLanguage } from "../../LanguageContext";

import data from "../../data/index.json";

export default function MyPortfolio() {
  const openGitHubLink = () => {
    window.open("https://github.com/xMal0c", "_blank");
  };

  const { language } = useLanguage();
  const content = {
    en: {
      subTitle: "Recent Projects",
      heading: "My Portfolio",
      btnText: "Visit My GitHub",
      viewInGitHub: "View In GitHub",
    },
    fi: {
      subTitle: "Viimeisimmät projektit",
      heading: "Portfolio",
      btnText: "Kurkkaa GitHubiani",
      viewInGitHub: "Näytä GitHubissa",
    },
  };

  const portfolio = data?.portfolio?.[language] || [];

  return (
    <section className="portfolio--section" id="MyPortfolio">
      <div className="portfolio--container--box">
        <div className="portfolio--container">
          <p className="sub--title">{content[language].subTitle}</p>
          <h2 className="section--heading">{content[language].heading}</h2>
        </div>
        <div>
          <button className="btn btn-github" onClick={openGitHubLink}>
            <FontAwesomeIcon icon={faGithub} size="xl" />{" "}
            {content[language].btnText}
          </button>
        </div>
      </div>
      <div className="portfolio--section--container">
        {portfolio?.map((item, index) => (
          <div key={index} className="portfolio--section--card">
            <div className="portfolio--section--img">
              <img src={item.src} alt="Project" />
            </div>
            <div className="portfolio--section--card--content">
              <div>
                <h3 className="portfolio--section--title">{item.title}</h3>
                <p className="text-md">{item.description}</p>
              </div>
              <a
                href="https://github.com/xMal0c"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm portfolio--link"
              >
                {content[language].viewInGitHub}{" "}
                <FontAwesomeIcon icon={faSquareArrowUpRight} />
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
