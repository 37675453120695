import data from "../../data/index.json";

import { useLanguage } from "../../LanguageContext";

export default function MySkills() {
  const { language } = useLanguage();

  const content = {
    en: {
      contentTitle: "My Skills",
      title: "In a nutshell",
    },
    fi: {
      contentTitle: "Taitoni",
      title: "Pähkinänkuoressa",
    },
  };

  const skills = data?.skills?.[language] || [];

  return (
    <section className="skills--section" id="mySkills">
      <div className="portfolio--container">
        <p className="section--title">{content[language].contentTitle}</p>
        <h2 className="skills--section--heading">{content[language].title}</h2>
      </div>
      <div className="skills--section--container">
        {skills.map((item, index) => (
          <div key={index} className="skills--section--card">
            <div className="skills--section--img">
              <img src={item.src} alt="Product Chain" />
            </div>
            <div className="skills--section--card--content">
              <h3 className="skills--section--title">{item.title}</h3>
              <p className="skills--section--description">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
