import { useLanguage } from "../../LanguageContext";

export default function AboutMe() {
  const { language } = useLanguage();

  const content = {
    en: {
      title: "About",
      heading: "About Me",
      description: `I am about to graduate as a software developer. I have diverse experience across various fields, which has given me a unique perspective on user needs and insights into developing effective solutions. I prefer working independently, but I also enjoy teamwork.`,
      additionalDescription: `I'm also capable of working remotely, so I am ready to work from anywhere. I am trilingual, able to communicate fluently in Finnish, English, and Polish.`,
    },
    fi: {
      title: "Tietoa",
      heading: "Minusta",
      description: `Olen juuri valmistumassa ohjelmistokehittäjäksi. Minulla on monipuolista kokemusta eri aloilta, joiden kautta olen saanut ainutlaatuisen näkökulman käyttäijen tarpeisiin ja oivalluksia tehokkaiden ratkaisujen kehittämiseen. Työskentelen mieluiten itsenäisesti, mutta nautin myös tiimityöskentelystä.`,
      additionalDescription: `Pystyn tekemään töitä myös etänä, joten olen valmis työskentelemään missä tahansa. Olen kolmikielinen, joten pystyn kommunikoimaan sujuvasti suomeksi, englanniksi ja puolaksi.`,
    },
  };

  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/about-me.webp" alt="About Me" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <p className="section--title">{content[language].title}</p>
          <h1 className="skills-section--heading">
            {content[language].heading}
          </h1>
          <p className="hero--section-description">
            {content[language].description}
          </p>
          <p className="hero--section-description">
            {content[language].additionalDescription}
          </p>
        </div>
      </div>
    </section>
  );
}
