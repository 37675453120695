import React from "react";

export default function NotFound() {
  return (
    <section className="not-found--section">
      <div className="not-found--section--container">
        <h1 className="not-found--section--heading">404</h1>
        <p className="not-found--section--description">Page not found</p>
        <a href="/" className="not-found--section--return">
          Return Home
        </a>
      </div>
    </section>
  );
}
